import { Box, Typography, CircularProgress } from '@mui/material';
import "../Styles/UnderConstruction.css"

const UnderConstruction = () => {

    const UnderConstructionSvg = () => (
        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 18" viewBox="0 0 100 125" x="0px" y="0px" className='underconstruction-work'>
            <path d="M43.23,74.21c-3,0-6.75,6.5-6.75,6.5H87.23s-2-7.5-7.5-7.5-4-8.5-9.25-8.5-2.5-6-10.25-6c-5,0-7.2,3.44-8.72,5.88L41.3,58.69a1.87,1.87,0,0,0,.36-1.56l-3.48-15.7.94-3.93a4.51,4.51,0,0,0-3.32-5.43l-4-1c-2.1-.51-2.78.08-4.53.69l-7.21,4a2,2,0,0,0-.95,2.41l3.16,9.55-8.45-4.88-1.09,1.89,9.8,5.66-.78,3.25c-.1.4-.6,10.55-.43,11L19.65,77.53a2.81,2.81,0,0,0,2.41,3.14l.45.06a2.81,2.81,0,0,0,3.14-2.41l1.8-13.63c.15-.33.52-4.93.52-4.93s1.42.31,1.57.33l4.24,7.34L29.61,77.5a2.88,2.88,0,0,0,1,3.5,2.8,2.8,0,0,0,4.15-1.25l4.92-11.91a3.17,3.17,0,0,0,.17-3.42l-3.52-6.09,13.93,8C46.41,66.08,46.23,74.21,43.23,74.21ZM37.7,56.62,34.91,55l1.27-5.26ZM24.48,42.43l-1.13-3.51,2.17-.8ZM31.54,21.7a6.36,6.36,0,1,1,2.33,8.69A6.36,6.36,0,0,1,31.54,21.7Z" />
        </svg>
    );
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            textAlign="center"
        >
            {/* <CircularProgress size={80} /> */}
            <UnderConstructionSvg />
            <Typography variant="h4" style={{ marginTop: '20px' }}>
                Work in Progress
            </Typography>
            <Typography variant="body1" style={{ marginTop: '10px', color: 'gray' }}>
                We're working hard to bring you this feature. Stay tuned!
            </Typography>
        </Box>
    )
}

export default UnderConstruction;