import { useEffect, useState } from "react";
import "../Styles/ProjectDataDashboard.css"
import Utils from "../services/Utils";

function ProjectDataDashboard() {
    const [projectData, setProjectData] = useState([])
    async function fetchData() {
        try {
            const env = Utils.getEnvVars();
            let endpoint = env.API_URL;
            let url = endpoint + "/projectStatus";       
            const queryParams = {};
            const queryString = Object.keys(queryParams)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
                .join('&');
            if (queryString) url = `${url}?${queryString}`      
            const options = {
              method: "GET"
            };
            const response = await fetch(new Request(url, options)) 
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const dataRes = await response.json();
            const data = 'json_data' in dataRes ? dataRes['json_data'] : []
            // const data = story_points_summary;
            const col = Object.keys(data[0])     
            let searchString = "status"
            let searchindex = col.findIndex(colname => colname.trim().toLowerCase().includes(searchString));
            let totalProjects = data.length;
            let inProgressProjects = 0
            let upComingProjects = 0
            let completedProjects = 0
            data.filter((di)=> {
                (di[col[searchindex]].trim().toLowerCase() === 'on hold' || di[col[searchindex]].trim().toLowerCase() === 'up coming' || di[col[searchindex]].trim().toLowerCase() === 'yet to start') && upComingProjects++
                di[col[searchindex]].trim().toLowerCase() === 'in progress' && inProgressProjects++
                di[col[searchindex]].trim().toLowerCase() === 'completed' && completedProjects++
            })
            setProjectData([
                { title: 'Total Projects', count: totalProjects, period: 'Jan 1 - Dec 1', increaseby:'0%' },
                { title: 'In Progress Projects', count: inProgressProjects, period: 'Jan 1 - Dec 1', increaseby:'0%' },
                { title: 'Upcoming Projects', count: upComingProjects, period: 'Jan 1 - Dec 1', increaseby:'0%'},
                { title: 'Completed Projects', count: completedProjects, period: 'Jan 1 - Dec 1', increaseby:'0%' }
            ]);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }
    useEffect(() => {
        setProjectData([
            { title: 'Total Projects', count: 0, period: 'Jan 1 - Dec 1', increaseby:'0%' },
            { title: 'In Progress Projects', count: 0, period: 'Jan 1 - Dec 1', increaseby:'0%' },
            { title: 'Upcoming Projects', count: 0 , period: 'Jan 1 - Dec 1', increaseby:'0%'},
            { title: 'Completed Projects', count: 0, period: 'Jan 1 - Dec 1', increaseby:'0%' }
        ]);
        fetchData()
    }, [])    
    return (
        <>
        {projectData && projectData.map((item) => <>
        <div className="mb-4 col-xl-4 col-sm-6 col-12">
            <div className="shadow-sm card border-light">
                <div className="card-body">
                    <div className="d-block d-xl-flex align-items-center row">                        
                        <div className="px-xl-0 col-xl-12 col-12 align-text-center">
                            <div className="d-none d-sm-block">
                                <h5>{item.title}</h5>
                                <h3 className="mb-1">{item.count}</h3>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>)}
        </>
    )
}

export default ProjectDataDashboard;