import { useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
// import { BarChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Bar, Rectangle } from 'recharts';

// import Team_Occupancy_Details from "../data/Team_Occupancy_Details.json"
import "../Styles/ProjectProgressMini.css"
import "../Styles/AverageOcc.css"
import Utils from "../services/Utils";

function AvergaOccupancy() {
    const [teamOccupancy, setTeamOccupancy] = useState({})
    const [kcname,setkcname] = useState({})    
    async function teamOccupancyWrap() {
        try {
            const env = Utils.getEnvVars();
            let endpoint = env.API_URL;
            let url = endpoint + "/occupancy";                    const queryParams = {
            };
            const queryString = Object.keys(queryParams)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
                .join('&');
            if (queryString) url = `${url}?${queryString}`      
            const options = {
              method: "GET"
            };
            const response = await fetch(new Request(url, options))
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const dataRes = await response.json();
            const data = 'json_data' in dataRes ? dataRes['json_data'] : []
            // const data = Team_Occupancy_Details;
            Utils.setTeamOccupany(data,"primary area",setkcname,setTeamOccupancy)
            // const col = Object.keys(data[0])
            // console.log("averageOccupancy col ", col)
            // let searchString = "primary area"
            // let searchindex = col.findIndex(colname => colname.trim().toLowerCase().includes(searchString));
            // console.log("averageOccupancy index ", searchindex)
            // let leadObj = {}
            // data.filter((innerData) => {
            //     if (!leadObj[innerData[col[searchindex]]]) leadObj[innerData[col[searchindex]]] = []
            //     innerData['avgOccupancy'] = 0
            //     innerData['avgAvailableOccupancy'] = 0
            //     leadObj[innerData[col[searchindex]]].push(innerData)
            // })
            // console.log("leadObj ", leadObj)
            // let occCol = []
            // let avaCol = []
            // let searchString1 = "occupancy"
            // let searchString2 = "availability"
            // col.filter((colitem,idx) => {
            //     console.log(colitem,idx)
            //     colitem.trim().toLowerCase().includes(searchString1) && occCol.push(idx)
            //     colitem.trim().toLowerCase().includes(searchString2) && avaCol.push(idx)
            // })
            // console.log("averageOccupancy index", searchindex, occCol, avaCol)

            // function getAverageByKey(data, keyName) {
            //     const values = data.map(obj => obj[keyName]).filter(value => value !== undefined && !isNaN(value));
            //     const sum = values.reduce((acc, value) => parseInt(acc) + parseInt(value), 0);
            //     const average = values.length ? sum / values.length : 0;
            //     data.map(obj => {
            //         let inneravgval = parseInt(Math.round(average))
            //         obj[`${keyName}_average`] = !isNaN(inneravgval) ? inneravgval : 0;
            //         return obj
            //     });
            // }

            // let leadKeyColName = [{'col':"Occupancy",'colinner':'Occupancy (%)','color':'#DBAE58'},{'col':"Availability",'colinner':'Availability (%)','color':'#488A99'}]
            // let keyColName = {}
            // leadKeyColName.filter((litem) => {
            //     let kcn = Object.keys(keyColName)
            //     if(!kcn.includes(litem['colinner'])) {
            //         keyColName[litem['colinner']] = {'colinner': litem['colinner'], 'color': litem['color']}
            //     }
            // })
            // setkcname(keyColName)
            // console.log("keyColName",keyColName)

            // let leadKeyColName1 = []
            // let stackIdx = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
            // occCol.filter((occColItem,idx) => {
            //     let innerLeadKey = {'col':col[occColItem],'stack': stackIdx[idx],'color':'#DBAE58'}
            //     leadKeyColName1.push(innerLeadKey)
            // })
            // avaCol.filter((avaColItem,idx) => {
            //     let innerLeadKey = {'col':col[avaColItem],'stack': stackIdx[idx],'color':'#488A99'}
            //     leadKeyColName1.push(innerLeadKey)
            // })
            // console.log("leadKeyColName1 ",leadKeyColName1)
            // let leadOccuObj = {};
            // let leadNames = []
            // Object.keys(leadObj).filter((lead) => {
            //     leadKeyColName1.filter((keyCol) => {
            //         let keyName = keyCol['col'];
            //         getAverageByKey(leadObj[lead], keyName);
            //         if (!leadOccuObj[keyName]) leadOccuObj[keyName] = { 'stack': keyCol['stack'], label: keyName }
            //         if (!leadOccuObj[keyName]['data']) leadOccuObj[keyName]['data'] = []
            //         leadOccuObj[keyName].data.push(leadObj[lead][0][`${keyName}_average`])
            //         leadOccuObj[keyName]['color'] = keyCol['color']
            //     })                
            //     if (lead === 'Global Transparency') {
            //         leadNames.push('GT')
            //     } else if (lead === 'Ethics & Compliance') {
            //         leadNames.push('E & C')
            //     } else if (lead === 'GBS Automation') {
            //         leadNames.push('GBS')
            //     } else {
            //         leadNames.push(lead)
            //     }
            // })
            // console.log("leadOccuObj ",leadOccuObj)
            // console.log("leadNames ",leadNames)
            // console.log(leadObj, leadNames, Object.values(leadOccuObj))
            // setTeamOccupancy({ lead: leadNames, series: Object.values(leadOccuObj), leadObj, ...Utils.returnRowCol(data) })
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }

    useEffect(() => {
        teamOccupancyWrap()
    }, [])

    return (
        <div className='shadow-sm card border-light'>
            <div className='css-1dqbqs5'>
                <p class="css-nmf4xv">Capacity Forecast</p>
            </div>
            <div>
                <div className="legendclass">
                    <table>
                        <tr>
                            {
                                Object.values(kcname).map((itemCol) => {
                                    return (
                                        <td><button style={{ "position": "relative", "top": "4px", "backgroundColor": itemCol['color'], "height": "20px", "width": "20px", "border": "0px" }}></button> {itemCol['colinner']}</td>
                                    )
                                })
                            }                            
                        </tr>
                    </table>
                </div>
                <div className="occclass">                
                    <BarChart
                    xAxis={[{
                        scaleType: 'band', data: teamOccupancy.lead ? teamOccupancy.lead : [], categoryGapRatio: 0.3,
                        barGapRatio: 0.1
                    }]}
                    series={teamOccupancy.series ? teamOccupancy.series : []}
                    height={275}
                    barLabel="value"
                    slotProps={{
                        legend: {
                            labelStyle: {
                                fontSize: 10,
                                fill: 'blue',
                                display: 'none'
                            },
                            hidden: true
                        },
                    }}
                />
                </div>
            </div>
        </div>
    )
}

export default AvergaOccupancy;