import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
// import project_status from "../data/project_status.json"
import Utils from "../services/Utils";
import { BarChart } from "@mui/x-charts/BarChart";

function Projects() {
    const [dataTable, setDataTable] = useState([])
    const [showTeamO, setshowTeamO] = useState(false)
    const [showData, setShowData] = useState(false)
    const [areaData, setAreaData] = useState([])
    const [areaDataObj, setAreaDataObj] = useState({})
    useEffect(() => {
        fetchData()
    }, [])
    async function fetchData() {
        try {
            const env = Utils.getEnvVars();
            let endpoint = env.API_URL;
            let url = endpoint + "/projectStatus";
            console.log(url)
            const queryParams = {

            };
            const queryString = Object.keys(queryParams)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
                .join('&');
            if (queryString) url = `${url}?${queryString}`
            const options = {
                method: "GET"
            };
            const response = await fetch(new Request(url, options))
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const dataRes = await response.json();
            const data = 'json_data' in dataRes ? dataRes['json_data'] : []
            //   const data = project_status;
            function getAverageByKey(data, keyName) {
                const values = data.map(obj => obj[keyName]).filter(value => value !== undefined && !isNaN(value));
                const sum = values.reduce((acc, value) => parseInt(acc) + parseInt(value), 0);
                const average = values.length ? sum / values.length : 0;
                data.map(obj => {
                    obj[`${keyName}_average`] = parseInt(Math.round(average));
                    return obj
                });
            }
            data.map((innerData) => {
                innerData['health'] = innerData['Project Health'].trim() === 'Yellow' ? 2 : innerData['Project Health'].trim() === 'Red' ? 1 : innerData['Project Health'].trim() === 'Green' ? 3 : innerData['Project Health'].trim() === 'Blue' ? 4 : innerData['Project Health'].trim() === 'Grey' ? 5 : 0
            })
            setDataTable(Utils.returnRowCol(data, true));
            setshowTeamO(true);
            console.log(data)
            let areaObj = {}
            data.filter((innerData) => {
                if (!areaObj[innerData['GS Area']]) areaObj[innerData['GS Area']] = []
                areaObj[innerData['GS Area']].push(innerData)
            })
            let areaObj1 = {}
            Object.keys(areaObj).filter((innerData) => {
                console.log(innerData)
                if (!areaObj1[innerData]) areaObj[innerData] = { label: areaObj[innerData][0]['GS Area'] }
                // getAverageByKey(areaObj[innerData], 'Project Name');
                console.log('new ',areaObj)
                // if (!areaObj[innerData['GS Area']]['data']) areaObj[innerData['GS Area']]['data'] = []
                // areaObj[innerData['GS Area']].data.push(data[innerData][0][`${keyName}_average`])
                // leadOccuObj[keyName]['color'] = keyCol['color']
                // areaObj[innerData['GS Area']].push(innerData)
            })
            console.log("areaObj ", areaObj)
            // // setAreaDataObj(areaObj)
            // console.log({'area':Object.keys(areaObj), series: Object.values(areaObj), areaObj, ...Utils.returnRowCol(data,true)})
            // setAreaDataObj({'area':Object.keys(areaObj), series: Object.values(areaObj), areaObj, ...Utils.returnRowCol(data,true)})
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }
    const getValue = () => {
        const dd = document.getElementById('dropdown');
        const value = dd.value;
        var text = dd.selectedIndex;

        console.log(value);
        console.log(text)
        // console.log(rows[text].url);
        // settext(rows[text].url)
    }

    const onItemClick = (event, params) => {
        console.log(event, params)

        // let leadName = teamOccupancy.lead[params.dataIndex];
        // setTeamOccupancyRows(teamOccupancy.leadObj[leadName])
        // setshowTeamO(true)
    }
    return (
        <div>
            <h2>Projects Status</h2>
            <div className='row teamoccupancyclass'>
                {showTeamO ?
                    <div style={{ width: '100%' }}>
                        {/* {'area' in areaDataObj && areaDataObj.area.length > 0 && <div><BarChart
                        xAxis={[{
                            scaleType: 'band', data: areaDataObj.area ? areaDataObj.area : [], categoryGapRatio: 0.3,
                            barGapRatio: 0.1
                        }]}
                        series={areaDataObj.series ? areaDataObj.series : []}
                        width={600}
                        height={350}
                        barLabel="value"
                        slotProps={{
                            legend: {
                                labelStyle: {
                                    fontSize: 10,
                                    fill: 'blue',
                                    display: 'none'
                                },
                                hidden: true
                            },
                        }}
                        onItemClick={onItemClick}
                    /></div>} */}
                        {/* <div><label for="dropdown" className="dd-label">GS Area: </label>
                            <select name="dropdown" id="dropdown" onChange={getValue}>
                                <option value="" className="def-dd-option  dd-list">Select your option</option>
                                {
                                    areaDataObj.area.map((key) => (
                                        <option className='dd-list' value={key}>{key}</option>
                                    ))
                                }
                            </select></div> */}
                            {!showData && <div>
                            <DataGrid
                                columns={dataTable.rrccolumns}
                                rows={dataTable.rrcrows}
                                columnVisibilityModel={{
                                    id: false,
                                    health: false,
                                    ' Scrum master': false
                                }}
                                sortModel={[{ field: "health", sort: "asc", type: "number" }]}
                            /></div>}
                    </div> : <></>
                }
            </div>
        </div>
    )
}

export default Projects;