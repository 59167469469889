import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
// import project_status from "../data/project_status.json"
import "../Styles/ProjectProgressMini.css"
import Utils from '../services/Utils';

function ProjectProgressMini() {
    const [teamOccupancy, setTeamOccupancy] = useState({})
    const [areaList, setAreaList] = useState([])
    const [resdata, setResData] = useState([])
    const [areaObj, setareaObj] = useState({})
    const [csvcolname, setcsvcolname] = useState([])
    const [clearLine, setClearLine] = useState(false)
    async function fetchData() {
        try {
            const env = Utils.getEnvVars();
            let endpoint = env.API_URL;
            let url = endpoint + "/projectStatus";
            const queryParams = {
            };
            const queryString = Object.keys(queryParams)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
                .join('&');
            if (queryString) url = `${url}?${queryString}`
            const options = {
                method: "GET"
            };
            const response = await fetch(new Request(url, options))
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const dataRes = await response.json();
            const data = 'json_data' in dataRes ? dataRes['json_data'] : []
            setResData(data);
            // const data = project_status;
            const col = Object.keys(data[0])
            setcsvcolname(col);            
            let searchString4 = "area"
            let searchindex4 = col.findIndex(colname => colname.trim().toLowerCase().includes(searchString4));
            let areaField = {}
            data.filter((tu) => {
                if (!areaField[tu[col[searchindex4]]]) areaField[tu[col[searchindex4]]] = []
                areaField[tu[col[searchindex4]]].push({ ...tu })
            })
            setareaObj(areaField)
            setAreaList(Object.keys(areaField))
            teamOccupancyWrap(col, data)
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }

    const teamOccupancyWrap = (col, data) => {
        let searchString = "id"
        let searchindex = col.findIndex(colname => colname.trim().toLowerCase().includes(searchString));
        let searchString1 = "project name"
        let searchindex1 = col.findIndex(colname => colname.trim().toLowerCase().includes(searchString1));
        let searchString2 = "planned end date"
        let searchindex2 = col.findIndex(colname => colname.trim().toLowerCase().includes(searchString2));
        let searchString3 = "project health"
        let searchindex3 = col.findIndex(colname => colname.trim().toLowerCase().includes(searchString3));
        let searchString4 = "area"
        let searchindex4 = col.findIndex(colname => colname.trim().toLowerCase().includes(searchString4));
        let idata = []
        data.filter((innerData) => {
            if (innerData[col[searchindex4]] === 'Global Transparency ') {
                innerData[col[searchindex4]] = 'GT'
            } else if (innerData[col[searchindex4]] === 'Ethics & Compliance ') {
                innerData[col[searchindex4]] = 'E & C'
            } else if (innerData[col[searchindex4]] === 'GBS Automation') {
                innerData[col[searchindex4]] = 'GBS'
            } else if (innerData[col[searchindex4]] === 'Customer Meeting Services') {
                innerData[col[searchindex4]] = 'CMS'
            } else if (innerData[col[searchindex4]] === 'Global Records Management') {
                innerData[col[searchindex4]] = 'GRM'
            } else {
                innerData[col[searchindex4]] = innerData[col[searchindex4]]
            }
            let id = {
                'id': innerData[col[searchindex]],
                'Area': innerData[col[searchindex4]],
                'Project Name': innerData[col[searchindex1]],
                'Planned End Date': innerData[col[searchindex2]].trim(),
                'Project Health': innerData[col[searchindex3]],
                'health': innerData[col[searchindex3]].trim().toLowerCase() === 'yellow' ? 2 : innerData[col[searchindex3]].trim().toLowerCase() === 'red' ? 1 : innerData[col[searchindex3]].trim().toLowerCase() === 'green' ? 3 : innerData[col[searchindex3]].trim().toLowerCase() === 'blue' ? 4 : innerData[col[searchindex3]].trim().toLowerCase() === 'grey' ? 5 : 0
            }
            idata.push(id)
        })
        setTeamOccupancy({ ...Utils.returnRowCol(idata,true) })
    }

    const getValue = () => {
        setClearLine(true)
        const dd = document.getElementById('areadropdown');
        const value = dd.value;
        var text = dd.selectedIndex;
        if (value === "" && text === 0) {
            teamOccupancyWrap(csvcolname, resdata)
            return
        }
        let searchareaindx = areaList.findIndex(colname => colname.trim().toLowerCase().includes(value.trim().toLowerCase()));
        let areaNameList = areaObj[areaList[searchareaindx]]
        teamOccupancyWrap(csvcolname, areaNameList)
    }

    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div className='shadow-sm card border-light'>
            <div className='css-1dqbqs5'>
                <p class="css-nmf4xv">Project Health</p>
            </div>
            <div>
                <ThemeProvider theme={createTheme()}>
                    {areaList.length > 0 &&
                        <>
                            <div className="align-text-center"><label for="dropdown" className="dd-label">Area: </label>
                                <select name="dropdown" className="utilization-select-class" id="areadropdown" onChange={getValue}>
                                    <option value="" className="def-dd-option  dd-list">Select your option</option>
                                    {
                                        areaList.map((key, idx) => (
                                            <option className='dd-list' value={key}>{key}</option>
                                        ))
                                    }
                                </select></div>
                            <div style={{ width: '100%', height: 1084, 'overflow-x': 'hidden', 'overflow-y': 'scroll' }}>
                                <div sx={{ backgroundColor: 'grey', padding: '5px' }}>
                                    <DataGrid
                                        columns={teamOccupancy.rrccolumns ? teamOccupancy.rrccolumns : []}
                                        rows={teamOccupancy.rrcrows ? teamOccupancy.rrcrows : []}
                                        columnVisibilityModel={{
                                            id: false,
                                            health: false
                                        }}
                                        sortModel={[{ field: "health", sort: "asc", type: "number" }]}
                                        style={{ margin: '0 10px' }}
                                    />
                                </div>
                            </div>
                        </>
                    }
                </ThemeProvider>
            </div>
        </div>
    );
}

export default ProjectProgressMini;