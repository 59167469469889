import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button, Typography, Box } from '@mui/material';
import image1 from '../img/outing12.jpg';
import image2 from '../img/outing2.jpg';
import image3 from '../img/outing3.jpg';

const BannerCarousel = () => {
    const items = [
        {
            name: "Team Building Activity",
            description: "An exciting day of team building and bonding.",
            background: image1
        },
        {
            name: "Outdoor Adventure",
            description: "A thrilling outdoor adventure with the team.",
            background: image2
        },
        {
            name: "Workshop",
            description: "A productive workshop to enhance our skills.",
            background: image3
        }
    ];

    return (
        <Carousel>
            {items.map((item, index) => (
                <Item key={index} item={item} />
            ))}
        </Carousel>
    );
}

const Item = ({ item }) => {
    return (
        <Paper
            sx={{
                position: 'relative',
                height: '400px',
                color: 'white',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${item.background})`
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    padding: '20px',
                }}
            >                
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    textAlign: 'right',
                    padding: '20px',
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    width: '100%',
                    boxSizing: 'border-box',
                }}
            >
                <Typography variant="h3">{item.name}</Typography>
                <Typography variant="h6">{item.description}</Typography>
            </Box>
        </Paper>
    );
}

export default BannerCarousel;
