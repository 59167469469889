import { useEffect, useState } from "react";
import Utils from "../services/Utils";
import { DataGrid } from "@mui/x-data-grid";
// import spilled_over_tasks from "../data/spilled_over_tasks.json"

function SprintSpillOver() {
    const [spillOver, setSpillOver] = useState({})
    const [showTeamO, setshowTeamO] = useState(false)
    useEffect(() => {
        fetchDataSpillOver()
    }, [])
    async function fetchDataSpillOver() {
        try {
            const env = Utils.getEnvVars();
            let endpoint = env.API_URL;
            let url = endpoint + "/spillover";
            console.log(url)
            const queryParams = {

            };
            const queryString = Object.keys(queryParams)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
                .join('&');
            if (queryString) url = `${url}?${queryString}`
            const options = {
                method: "GET"
            };
            const response = await fetch(new Request(url, options))
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const dataRes = await response.json();
            const data = 'json_data' in dataRes ? dataRes['json_data'] : []
            //   const data = spilled_over_tasks;
            setSpillOver(Utils.returnRowCol(data, true));
            console.log('Data received:', data);
            setshowTeamO(true);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }
    return (
        <div>
            <h2>Sprint Backlog</h2>
            <div className='row teamoccupancyclass'>
                {showTeamO ?
                    <div style={{ width: '100%' }}>
                        <DataGrid
                            columns={spillOver.rrccolumns}
                            rows={spillOver.rrcrows}
                            columnVisibilityModel={{
                                id: false
                            }}
                            sortModel={[{ field: "Sprint Count", sort: "desc", type: "number" }]}
                        />
                    </div> : <></>
                }
            </div>
        </div>
    )
}

export default SprintSpillOver;