import UnderConstruction from "./UnderConstruction";

function Overview () {
    return (
        <div>
            <h2>Overview</h2>
            <UnderConstruction />
        </div>
    )
}

export default Overview;