import React from 'react';
import "../Styles/Footer.css"

function Footer() {
    return (
        <div className="footer-container">
            <div className="text">
                Copyright © 2024 Eli Lilly and Company and its affiliates. All rights reserved.
            </div>
        </div>
    );
}

export default Footer;