import React, { useEffect, useState } from 'react';

import "../Styles/Sidebar.css"
import { Link } from 'react-router-dom';

function Sidebar({elementHeight}) {
    const [activeSection, setActiveSection] = useState('Home');
    const toggleSection = (section) => {
        setActiveSection(activeSection === section ? null : section);
    };

    const [activeDrop, setActiveDrop] = useState(null);
    const toggleDrop = (section) => {
        setActiveDrop(activeDrop === section ? null : section);
    };

    useEffect(() => {
        let menulist = ["TeamOccupancy","SprintDetails","SprintUtilization","SprintSpillOver","UpcomingSprints","Overview","Projects","Costing","Notification","AboutTeam"];
        let menudroplist = ["TeamOccupancy","SprintDetails","SprintUtilization","SprintSpillOver","UpcomingSprints","Overview"];
        menulist.filter((ml) => {
            if(window.location.href.includes(ml)) {
                if(menudroplist.includes(ml)) {
                    setActiveDrop('Sprints')
                }
                setActiveSection(ml);
            }
        });
    },[])

    return (
        <nav className="sidebar" id='sidebar' style={{
            height: `calc(${elementHeight}px - 52px)`,
            transition: 'height 0.3s ease',
        }}>
            <ul>
                <li onClick={() => toggleSection('Home')} className={activeSection === 'Home' ? 'active' : ''}><Link to="/">
                    <div className='menu-item'>
                        Home
                    </div>
                </Link></li>
                <li onClick={() => toggleDrop('Sprints')}>
                    <div className='menu-item'>
                        <Link to="#">Sprints</Link>                        
                        {activeDrop === 'Sprints' ? (
                            <svg width="24px" height="auto" viewBox="0 0 24 24">
                                <path d="M7 14l5-5 5 5H7z" />
                            </svg>
                        ) : (
                            <svg width="24px" height="auto" viewBox="0 0 24 24">
                                <path d="M7 10l5 5 5-5H7z" />
                            </svg>
                        )}
                    </div>
                </li>
                {activeDrop === 'Sprints' && (
                    <>
                        <li onClick={() => toggleSection('TeamOccupancy')} className={activeSection === 'TeamOccupancy' ? 'active' : ''}><Link to="/TeamOccupancy" className='sidemenu-tab'><div className='menu-item'>Capacity Forecast</div></Link></li>
                        <li onClick={() => toggleSection('SprintDetails')} className={activeSection === 'SprintDetails' ? 'active' : ''}><Link to="/SprintDetails" className='sidemenu-tab'><div className='menu-item'>Sprint Details</div></Link></li>
                        <li onClick={() => toggleSection('SprintUtilization')} className={activeSection === 'SprintUtilization' ? 'active' : ''}><Link to="/SprintUtilization" className='sidemenu-tab'><div className='menu-item'>Sprint Utilization</div></Link></li>
                        <li onClick={() => toggleSection('SprintSpillOver')} className={activeSection === 'SprintSpillOver' ? 'active' : ''}><Link to="/SprintSpillOver" className='sidemenu-tab'><div className='menu-item'>Sprint Backlog</div></Link></li>
                        <li onClick={() => toggleSection('UpcomingSprints')} className={activeSection === 'UpcomingSprints' ? 'active' : ''}><Link to="/UpcomingSprints" className='sidemenu-tab'><div className='menu-item'>Upcoming Sprint</div></Link></li>
                        <li onClick={() => toggleSection('Overview')} className={activeSection === 'Overview' ? 'active' : ''}><Link to="/Overview" className='sidemenu-tab'><div className='menu-item'>Overview</div></Link></li>
                    </>
                )}
                <li onClick={() => toggleSection('Projects')} className={activeSection === 'Projects' ? 'active' : ''}><Link to="/Projects"><div className='menu-item'>Projects</div></Link></li>
                <li onClick={() => toggleSection('Operations')} className={activeSection === 'Operations' ? 'active' : ''}><Link to="https://lilly.service-now.com/now/nav/ui/classic/params/target/%24pa_dashboard.do%3Fsysparm_dashboard%3Dd67c6b5b473c4a54533082b5536d439e%26sysparm_tab%3D3e7cdbe147098258533082b5536d43a2%26sysparm_cancelable%3Dtrue%26sysparm_editable%3Dfalse%26sysparm_active_panel%3Dfalse" target='_blank'><div className='menu-item'>Operations</div></Link></li>
                <li onClick={() => toggleSection('Costing')} className={activeSection === 'Costing' ? 'active' : ''}><Link to="/Costing"><div className='menu-item'>Infra Costing</div></Link></li>
                <li onClick={() => toggleSection('Notification')} className={activeSection === 'Notification' ? 'active' : ''}><Link to="/Notification"><div className='menu-item'>Notification</div></Link></li>
                <li onClick={() => toggleSection('AboutTeam')} className={activeSection === 'AboutTeam' ? 'active' : ''}><Link to="/AboutTeam"><div className='menu-item'>About Team</div></Link></li>
            </ul>
        </nav>
    );
}

export default Sidebar;