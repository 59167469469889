import UnderConstruction from "./UnderConstruction";

function Notification () {
    return (
        <div>
            <h2>Notification</h2>
            <UnderConstruction />
        </div>
    )
}

export default Notification;