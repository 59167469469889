import React from 'react';
import '../Styles/Header.css';
import logo from '../img/LillyLogo_White.png'; // Make sure this path is correct
const Header = () => {
    const toggleSidebar = () => {
        let element = document.getElementById("sidebar");
        if(element) {
            element.style.display = element.style.display !== 'none' ? 'none' : 'block';
        } 
    }
        
    return (
        <div className="navbar-container">
            <div className="logo-section">
                <div>
                    <button className="menu-btn" tabindex="0" type="button" onClick={toggleSidebar}>
                        <svg fill="rgb(255, 255, 255)" style={{color: `rgb(255, 255, 255)`, paddingLeft: '0px', cursor: 'pointer'}} focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="AppsIcon">
                            <path d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z"></path>
                        </svg>
                        <span className='menu-btn-span'></span>
                    </button>
                </div>
                <img src={logo} alt="Logo" className="logo" />
                <hr className="logo-divider"></hr>
                <span className="logo-text">GS Dashboard</span>
            </div>
        </div>

    );
}

export default Header;
