import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import Home from '../components/Home';
import AboutTeam from '../components/AboutTeam';
import Notification from '../components/Notification';
import Costing from '../components/Costing';
import Projects from '../components/Projects';
import Overview from '../components/Overview';
import UpcomingSprints from '../components/UpcomingSprints';
import SprintSpillOver from '../components/SprintSpillOver';
import SprintUtilization from '../components/SprintUtilization';
import SprintDetails from '../components/SprintDetails';
import TeamOccupancy from '../components/TeamOccupancy';

const router = createBrowserRouter([{
  path: '/',
  element: <App />,
  children: [
    {
        path: "/",
        element: <Home /> 
    },
    {
        path: "/TeamOccupancy",
        element: <TeamOccupancy /> 
    },
    {
        path: "/SprintDetails",
        element: <SprintDetails /> 
    },
    {
        path: "/SprintUtilization",
        element: <SprintUtilization /> 
    },
    {
        path: "/SprintSpillOver",
        element: <SprintSpillOver /> 
    },
    {
        path: "/UpcomingSprints",
        element: <UpcomingSprints /> 
    },
    {
        path: "/Overview",
        element: <Overview /> 
    },
    {
        path: "/Projects",
        element: <Projects /> 
    },
    {
        path: "/Costing",
        element: <Costing /> 
    },
    {
        path: "/Notification",
        element: <Notification /> 
    },
    {
        path: "/AboutTeam",
        element: <AboutTeam /> 
    }     
  ]
},
])
export default router;
