import UnderConstruction from "./UnderConstruction";

function SprintDetails () {
    return (
        <div>
            <h2>Sprint Details</h2>
            <UnderConstruction />
        </div>
    )
}

export default SprintDetails;