import { useEffect, useState } from "react";
// import Team_Occupancy_Details from "../data/Team_Occupancy_Details.json"
import { BarChart } from "@mui/x-charts/BarChart";
import { DataGrid } from "@mui/x-data-grid";
import "../Styles/AverageOcc.css"
import Utils from "../services/Utils";
function TeamOccupancy() {
    const [resData, setResData] = useState([])
    const [teamOccupancyRows, setTeamOccupancyRows] = useState([])
    const [showTeamO, setshowTeamO] = useState(false)
    const [teamOccupancy, setTeamOccupancy] = useState({})
    const [kcname, setkcname] = useState({})
    const [skipcol, setskipcol] = useState({id:false,Lead:false,Name:false})
    async function teamOccupancyWrap() {
        try {
            const env = Utils.getEnvVars();
            let endpoint = env.API_URL;
            let url = endpoint + "/occupancy";        
            console.log(url)   
            const queryParams = {

            };
            const queryString = Object.keys(queryParams)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
                .join('&');
            if (queryString) url = `${url}?${queryString}`      
            const options = {
              method: "GET"
            };
            const response = await fetch(new Request(url, options)) 
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const dataRes = await response.json();
            const data = 'json_data' in dataRes ? dataRes['json_data'] : []
            const col = Object.keys(data[0])
            console.log("averageOccupancy col ", col)
            let searchString4 = "primary area"
            let searchindex4 = col.findIndex(colname => colname.trim().toLowerCase().includes(searchString4));
            data.map((innerData) => {
                console.log("innerdata",innerData)
                if (innerData[col[searchindex4]] === 'Global Transparency') {
                    innerData[col[searchindex4]] = 'GT'
                } else if (innerData[col[searchindex4]] === 'Ethics & Compliance') {
                    innerData[col[searchindex4]] = 'E & C'
                } else if (innerData[col[searchindex4]] === 'GBS Automation') {
                    innerData[col[searchindex4]] = 'GBS'
                } else if (innerData[col[searchindex4]] === 'Customer Meeting Services') {
                    innerData[col[searchindex4]] = 'CMS'
                } else if (innerData[col[searchindex4]] === 'Global Records Management') {
                    innerData[col[searchindex4]] = 'GRM'
                } else {
                    innerData[col[searchindex4]] = innerData[col[searchindex4]]
                }
                return innerData
            })
            setResData(data)
            // const data = Team_Occupancy_Details;
            console.log(data)

            
            Utils.setTeamOccupany(data,"primary area",setkcname,setTeamOccupancy)

            let searchString = "primary area"
            let searchindex = col.findIndex(colname => colname.trim().toLowerCase().includes(searchString));
            let searchString1 = "occupancy"
            let searchString2 = "availability"
            let searchString3 = "comments"
            const specificDate = new Date();
            const specificMonthAbbreviation = Utils.getMonthAbbreviation(specificDate);
            let monIdx = ['jan','feb','mar','apr','may','jun','jul','aug','sep','oct','nov','dec']
            let skipcolNew = {}
            col.filter((colitem, idx) => {
                let skipmm = false
                monIdx.filter((mm,mmidx) => {
                    if(colitem.trim().toLowerCase().includes(mm) && (colitem.trim().toLowerCase().includes(searchString1) || colitem.trim().toLowerCase().includes(searchString2) || colitem.trim().toLowerCase().includes(searchString3)) && mmidx < specificMonthAbbreviation.idx) {
                        console.log(colitem,mm)
                        skipmm = true;   
                                  
                    } 
                })   
                if(skipmm) {
                    if(!colitem.trim().toLowerCase().includes(col[searchindex])){
                        skipcolNew[colitem] = false
                    }
                }                
            })
            console.log("averageOccupancy index ", col,skipcolNew)
            setskipcol({...skipcol,...skipcolNew})

            let fullTeamOcc = Utils.returnRowCol(data,true)
            console.log("fullTeamOcc ", fullTeamOcc)
            setTeamOccupancyRows(fullTeamOcc.rrcrows)
            setshowTeamO(true)




            // let leadObj = {}
            // data.filter((innerData) => {
            //     if (!leadObj[innerData['Lead']]) leadObj[innerData['Lead']] = []
            //     innerData['avgOccupancy'] = 0
            //     innerData['avgAvailableOccupancy'] = 0
            //     leadObj[innerData['Lead']].push(innerData)
            // })
            // console.log("leadObj ", leadObj)
            // function getAverageByKey(data, keyName) {
            //     const values = data.map(obj => obj[keyName]).filter(value => value !== undefined && !isNaN(value));
            //     const sum = values.reduce((acc, value) => parseInt(acc) + parseInt(value), 0);
            //     const average = values.length ? sum / values.length : 0;
            //     data.map(obj => {
            //         obj[`${keyName}_average`] = parseInt(Math.round(average));
            //         return obj
            //     });
            // }
            // let leadNames = []
            // let leadOccupancy = []
            // let leadOccuObj = {};
            // leadKeyColName = [{ 'col': "Jul'24 Available Occupancy", 'colinner': 'July 24', 'stack': 'A', 'color': '#488A99' }, { 'col': "Jul'24 Occupancy", 'colinner': 'July 24', 'stack': 'A', 'color': '#488A99' }, { 'col': "Aug'24 Available Occupancy", 'colinner': 'Aug 24', 'stack': 'B', 'color': '#DADADA' }, { 'col': "Aug'24 Occupancy", 'colinner': 'Aug 24', 'stack': 'B', 'color': '#DADADA' }, { 'col': "Sep'24 Available Occupancy", 'colinner': 'Sep 24', 'stack': 'C', 'color': '#DBAE58' }, { 'col': "Sep'24 Occupancy", 'colinner': 'Sep 24', 'stack': 'C', 'color': '#DBAE58' }]
            // let keyColName = {}
            // leadKeyColName.filter((litem) => {
            //     let kcn = Object.keys(keyColName)
            //     if (!kcn.includes(litem['colinner'])) {
            //         keyColName[litem['colinner']] = { 'colinner': litem['colinner'], 'color': litem['color'] }
            //     }
            // })
            // setkcname(keyColName)
            // console.log("keyColName", keyColName)
            // Object.keys(leadObj).filter((lead) => {
            //     leadKeyColName.filter((keyCol) => {
            //         let keyName = keyCol['col'];
            //         getAverageByKey(leadObj[lead], keyName);
            //         if (!leadOccuObj[keyName]) leadOccuObj[keyName] = { 'stack': keyCol['stack'], label: keyName }
            //         if (!leadOccuObj[keyName]['data']) leadOccuObj[keyName]['data'] = []
            //         leadOccuObj[keyName].data.push(leadObj[lead][0][`${keyName}_average`])
            //         leadOccuObj[keyName]['color'] = keyCol['color']
            //     })

            //     leadNames.push(lead)
            // })
            // leadOccupancy.push(leadOccuObj)

            // console.log(leadObj, leadNames, Object.values(leadOccuObj))

            // setTeamOccupancy({ lead: leadNames, series: Object.values(leadOccuObj), leadObj, ...Utils.returnRowCol(data) })
            // let fullTeamOcc = Utils.returnRowCol(data)
            // console.log("fullTeamOcc ", fullTeamOcc)
            // setTeamOccupancyRows(fullTeamOcc.rrcrows)
            // setshowTeamO(true)

            // console.log('Data received:', data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }
    useEffect(() => {
        teamOccupancyWrap()
    }, [])

    const onItemClick = (event, params) => {
        console.log(event, params)
        let leadName = teamOccupancy.oldLead[params.dataIndex];
        console.log("leadname ",leadName,teamOccupancy)
        setTeamOccupancyRows(teamOccupancy.leadObj[leadName])
        setshowTeamO(true)
    }

    return (
        <div>
            <h2>Capacity Forecast</h2>
            <div className='row teamoccupancyclass'>
                <div><div className="legendclass">
                    <table>
                        <tr>
                            {
                                Object.values(kcname).map((itemCol) => {
                                    return (
                                        <td><button style={{ "position": "relative", "top": "4px", "backgroundColor": itemCol['color'], "height": "20px", "width": "20px", "border": "0px" }}></button> {itemCol['colinner']}</td>
                                    )
                                })
                            }
                        </tr>
                    </table>
                </div><BarChart
                        xAxis={[{
                            scaleType: 'band', data: teamOccupancy.lead ? teamOccupancy.lead : [], categoryGapRatio: 0.3,
                            barGapRatio: 0.1
                        }]}
                        series={teamOccupancy.series ? teamOccupancy.series : []}
                        width={600}
                        height={350}
                        barLabel="value"
                        slotProps={{
                            legend: {
                                labelStyle: {
                                    fontSize: 10,
                                    fill: 'blue',
                                    display: 'none'
                                },
                                hidden: true
                            },
                        }}
                        onItemClick={onItemClick}
                    />

                    {showTeamO ?
                        <div style={{ width: '100%' }}>
                            <DataGrid
                                columns={teamOccupancy.rrccolumns}
                                rows={teamOccupancyRows}
                                columnVisibilityModel={skipcol}
                            />
                        </div> : <></>}</div>
            </div>
        </div>
    )
}

export default TeamOccupancy;