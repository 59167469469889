import BannerCarousel from "./BannerCarousel";
import Team from "./Team";

function AboutTeam () {
    return (
        <div>
            <h2>About Team</h2>
            <BannerCarousel />
            <Team />
        </div>
    )
}

export default AboutTeam;