import config from "../config.json";

const Environment = config.Environment;

class UtilsWrap {
    getCurrentEnv() {
        if (process.env.APP_ENV) return process.env.APP_ENV;
        // Production
        if (
            window.location.hostname.includes('prod')
            || window.location.hostname === ''
            || window.location.hostname === 'gsdashboard.lilly.com'
        ) {
            return 'prod';
        }
        // Staging
        if (
            window.location.hostname.includes('qa')
            || window.location.hostname === ''
            || window.location.hostname === 'qa.gsdashboard.lilly.com'
        ) {
            return 'qa';
        }
        // Develop
        return 'dev';
    }

    getEnvVars() {
        const env = this.getCurrentEnv();
        const envVariables = Object.assign({}, Environment);
        return envVariables[env];
    }

    returnRowCol(rrcdata, flexWidth) {
        const rrckeys = Object.keys(rrcdata).length > 0 ? Object.keys(rrcdata[0]) : [];
        if (rrckeys.length < 0) {
            return {}
        }
        let that = this
        const rrccolumns = rrckeys?.map(element => {
            return (
                {
                    field: element,
                    headerName: element,
                    width: element === "id" ? 5 : element === 'Planned End Date' ? 100 : element === 'Project Health' ? 80 : element === 'Area' ? 80 : element === 'Primary Area' ? 100 : (element === "Aug'24 Occupancy (%)" || element === "Sept'24 Occupancy (%)" || element === "Aug'24 Availability (%)" || element === "Sept'24 Availability (%)") ? 140 : 150,
                    flex: flexWidth ? element === 'Planned End Date' ? 0 : element === 'Project Health' ? 0 : element === 'Area' ? 0 :  element === 'Primary Area' ? 0 : (element === "Aug'24 Occupancy (%)" || element === "Sept'24 Occupancy (%)" || element === "Aug'24 Availability (%)" || element === "Sept'24 Availability (%)") ? 0 : 1 : 0,
                    minWidth: 80,
                    align: (element === "Aug'24 Occupancy (%)" || element === "Sept'24 Occupancy (%)" || element === "Aug'24 Availability (%)" || element === "Sept'24 Availability (%)") ? 'center' : 'left',
                    headerAlign: (element === "Aug'24 Comments 4" || element === "Sept'24 Comments5" ) ? 'center' : 'left',
                    renderCell: (params) => {
                        if (element.includes("Available Occupancy")) {
                            return <div style={that.colorOfValue(params.value)}>{params.value}</div>
                        } else if (element.includes("Project Health")) {
                            return <div style={that.colorOfValueByColor(params.value)}>{params.value}</div>
                        }
                        return (params.value)
                    }
                }
            )
        });

        const rrcrows = rrcdata
        return { rrcrows, rrccolumns }
    }

    colorOfValue(value) {
        if (value >= 50) {
            return { backgroundColor: "lightgreen", textAlign: "center", backgroundSize: "100%" };
        }
        else if (value >= 30 && value < 50) {
            return { backgroundColor: "lightyellow", textAlign: "center" };
        }
        else {
            return { backgroundColor: "", textAlign: "center" }
        }
    }

    colorOfValueByColor(value) {
        if (value == 'Yellow ') {
            return { backgroundColor: "lightyellow", textAlign: "center", backgroundSize: "100%", color: "transparent" };
        }
        else if (value == 'Red') {
            return { backgroundColor: "#F1807E", textAlign: "center", color: "transparent" };
        }
        else if (value == 'Blue') {
            return { backgroundColor: "#87ceeb", textAlign: "center", color: "transparent" };
        }
        else if (value == 'Grey') {
            return { backgroundColor: "#DADADA", textAlign: "center", color: "transparent" };
        }
        else {
            return { backgroundColor: "lightgreen", textAlign: "center", color: "transparent" }
        }
    }

    getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    convertStringToPositiveNumber(number) {
        number = parseFloat(number)
        if (number > 0) {
            return number;
        } else if (number < 0) {
            return -number;
        } else {
            return number;
        }
    }

    setTeamUtilization(data, setAssigneeList, setAssigneeFinal, setClearLine, setkcname, assigneeornot) {
        let that = this;
        const col = Object.keys(data[0])
        let searchString = "yearmonth"
        let searchindex = col.findIndex(colname => colname.trim().toLowerCase().includes(searchString));
        let searchString1 = "occupancy"
        let searchindex1 = col.findIndex(colname => colname.trim().toLowerCase().includes(searchString1));
        let searchString2 = "availability"
        let searchindex2 = col.findIndex(colname => colname.trim().toLowerCase().includes(searchString2));
        let ym = {}
        data.filter((tu) => {
            if (!ym[tu[col[searchindex]]]) ym[tu[col[searchindex]]] = []
            ym[tu[col[searchindex]]].push({ ...tu })
        })
        !assigneeornot && setAssigneeList(Object.keys(ym))
        Object.keys(ym).filter((ymdata) => {
            ym[ymdata].map((yminnerdata) => {
                if (!ym[ymdata][0][col[searchindex1] + '_tot']) ym[ymdata][0][col[searchindex1] + '_tot'] = 0
                if (!ym[ymdata][0][col[searchindex2] + '_tot']) ym[ymdata][0][col[searchindex2] + '_tot'] = 0
                if (!ym[ymdata][0][col[searchindex1] + '_avg']) ym[ymdata][0][col[searchindex1] + '_avg'] = 0
                if (!ym[ymdata][0][col[searchindex2] + '_avg']) ym[ymdata][0][col[searchindex2] + '_avg'] = 0
                ym[ymdata][0][col[searchindex1] + '_tot'] = ym[ymdata][0][col[searchindex1] + '_tot'] + that.convertStringToPositiveNumber(yminnerdata[col[searchindex1]])
                ym[ymdata][0][col[searchindex2] + '_tot'] = ym[ymdata][0][col[searchindex2] + '_tot'] + that.convertStringToPositiveNumber(yminnerdata[col[searchindex2]])
                return yminnerdata
            })
            ym[ymdata][0][col[searchindex1] + '_avg'] = Math.round(ym[ymdata][0][col[searchindex1] + '_tot'] / ym[ymdata].length)
            ym[ymdata][0][col[searchindex2] + '_avg'] = Math.round(ym[ymdata][0][col[searchindex2] + '_tot'] / ym[ymdata].length)
        })
        let selected1 = { data: [], label: col[searchindex1], connectNulls: true, color: '#DBAE58' }
        let selected2 = { data: [], label: col[searchindex2], connectNulls: true, color: '#488A99' }
        Object.keys(ym).filter((ymdata) => {
            selected1.data.push(ym[ymdata][0][col[searchindex1] + '_avg'])
            selected2.data.push(ym[ymdata][0][col[searchindex2] + '_avg'])
        })
        let selectedTimeLine = [selected1, selected2];
        let ymlead = Object.keys(ym);
        console.log(ymlead)
        ymlead.sort();
        setAssigneeFinal({ lead: ymlead, series: Object.values(selectedTimeLine) })
        setClearLine(false)
        let keyColName = {
            1: { 'colinner': col[searchindex1], 'color': '#DBAE58' },
            2: { 'colinner': col[searchindex2], 'color': '#488A99' }
        }
        setkcname(keyColName)
    }

    setTeamUtilizationBar(data, setAssigneeList, setAssigneeFinal, setClearLine, setkcname, assigneeornot, barOrline) {
        let that = this;
        const col = Object.keys(data[0])
        let searchString = "yearmonth"
        let searchindex = col.findIndex(colname => colname.trim().toLowerCase().includes(searchString));
        let searchString1 = "occupancy"
        let searchindex1 = col.findIndex(colname => colname.trim().toLowerCase().includes(searchString1));
        let searchString2 = "availability"
        let searchindex2 = col.findIndex(colname => colname.trim().toLowerCase().includes(searchString2));
        let ym = {}
        data.filter((tu) => {
            if (!ym[tu[col[searchindex]]]) ym[tu[col[searchindex]]] = []
            ym[tu[col[searchindex]]].push({ ...tu })
        })
        !assigneeornot && setAssigneeList(Object.keys(ym))
        Object.keys(ym).filter((ymdata) => {
            let checkNonZeroLength = 0;
            ym[ymdata].map((yminnerdata) => {
                if (!ym[ymdata][0][col[searchindex1] + '_tot']) ym[ymdata][0][col[searchindex1] + '_tot'] = 0
                if (!ym[ymdata][0][col[searchindex2] + '_tot']) ym[ymdata][0][col[searchindex2] + '_tot'] = 0
                if (!ym[ymdata][0][col[searchindex1] + '_avg']) ym[ymdata][0][col[searchindex1] + '_avg'] = 0
                if (!ym[ymdata][0][col[searchindex2] + '_avg']) ym[ymdata][0][col[searchindex2] + '_avg'] = 0
                if(that.convertStringToPositiveNumber(yminnerdata[col[searchindex1]]) > 0 && that.convertStringToPositiveNumber(yminnerdata[col[searchindex2]]) > 0) {
                    checkNonZeroLength++
                }
                ym[ymdata][0][col[searchindex1] + '_tot'] = ym[ymdata][0][col[searchindex1] + '_tot'] + that.convertStringToPositiveNumber(yminnerdata[col[searchindex1]])
                ym[ymdata][0][col[searchindex2] + '_tot'] = ym[ymdata][0][col[searchindex2] + '_tot'] + that.convertStringToPositiveNumber(yminnerdata[col[searchindex2]])
                return yminnerdata
            })
            ym[ymdata][0][col[searchindex1] + '_avg'] = Math.round(ym[ymdata][0][col[searchindex1] + '_tot'] / checkNonZeroLength)
            ym[ymdata][0][col[searchindex2] + '_avg'] = Math.round(ym[ymdata][0][col[searchindex2] + '_tot'] / checkNonZeroLength)
        })

        let stackIdx = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
        let selectedObj1 = {};
        Object.keys(ym).filter((ymdata, idx) => {
            let keyName = col[searchindex1]
            if (!selectedObj1[keyName]) selectedObj1[keyName] = { 'stack': stackIdx[idx], label: keyName }
            if (!selectedObj1[keyName]['data']) selectedObj1[keyName]['data'] = []
            selectedObj1[keyName].data.push(ym[ymdata][0][col[searchindex1] + '_avg'])
            selectedObj1[keyName]['color'] = '#DBAE58'
        })
        let selectedObj2 = {};
        Object.keys(ym).filter((ymdata, idx) => {
            let keyName = col[searchindex2];
            if (!selectedObj2[keyName]) selectedObj2[keyName] = { 'stack': stackIdx[idx], label: keyName }
            if (!selectedObj2[keyName]['data']) selectedObj2[keyName]['data'] = []
            selectedObj2[keyName].data.push(ym[ymdata][0][col[searchindex2] + '_avg'])
            selectedObj2[keyName]['color'] = '#488A99'
        })

        let selectedTimeLine = [...Object.values(selectedObj1), ...Object.values(selectedObj2)]
        // selectedTimeLine.sort((a, b) => a.age - b.age);
        console.log("selectedTimeLine ",selectedTimeLine,ym)
        let ymlead = Object.keys(ym);
        console.log(ymlead)
        ymlead.sort();
        setAssigneeFinal({ lead: ymlead, series: selectedTimeLine })
        setClearLine(false)
        let keyColName = {
            1: { 'colinner': col[searchindex1], 'color': '#DBAE58' },
            2: { 'colinner': col[searchindex2], 'color': '#488A99' }
        }
        setkcname(keyColName)
    }

    setIndividualUtilization(assigneeObj, value, csvcolname, setAssigneeFinal, setClearLine, setkcname) {
        let selectedAssignee = assigneeObj[value]
        let selectedTimeList = []
        let searchString = "yearmonth"
        let searchindex = csvcolname.findIndex(colname => colname.trim().toLowerCase().includes(searchString));
        let searchString1 = "occupancy"
        let searchindex1 = csvcolname.findIndex(colname => colname.trim().toLowerCase().includes(searchString1));
        let searchString2 = "availability"
        let searchindex2 = csvcolname.findIndex(colname => colname.trim().toLowerCase().includes(searchString2));
        selectedAssignee.filter((sa) => {
            let keyName = sa[csvcolname[searchindex]]
            selectedTimeList.push(keyName)
        })

        let keyColName = {
            1: { 'colinner': csvcolname[searchindex1], 'color': '#DBAE58' },
            2: { 'colinner': csvcolname[searchindex2], 'color': '#488A99' }
        }

        setkcname(keyColName)

        let selectedTimeLine = {}
        selectedAssignee.filter((sa) => {
            if (sa[csvcolname[searchindex1]]) {
                let keyName = sa[csvcolname[searchindex]]
                if (!selectedTimeLine[csvcolname[searchindex1]]) selectedTimeLine[csvcolname[searchindex1]] = { label: csvcolname[searchindex1], connectNulls: true }
                if (!selectedTimeLine[csvcolname[searchindex1]]['data']) selectedTimeLine[csvcolname[searchindex1]]['data'] = []
                selectedTimeLine[csvcolname[searchindex1]].data.push(parseFloat(sa[csvcolname[searchindex1]]))
                selectedTimeLine[csvcolname[searchindex1]]['color'] = '#DBAE58'
            }
            if (sa[csvcolname[searchindex1]]) {
                let keyName = sa[csvcolname[searchindex]]
                if (!selectedTimeLine[csvcolname[searchindex2]]) selectedTimeLine[csvcolname[searchindex2]] = { label: csvcolname[searchindex2], connectNulls: true }
                if (!selectedTimeLine[csvcolname[searchindex2]]['data']) selectedTimeLine[csvcolname[searchindex2]]['data'] = []
                selectedTimeLine[csvcolname[searchindex2]].data.push(parseFloat(sa[csvcolname[searchindex2]]))
                selectedTimeLine[csvcolname[searchindex2]]['color'] = '#488A99'
            }
        })
        setAssigneeFinal({ lead: selectedTimeList, series: Object.values(selectedTimeLine) })
        setClearLine(false)
    }

    getMonthAbbreviation(date) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const monthIndex = date.getMonth(); // getMonth() returns a zero-based index (0-11)
        return {mon:months[monthIndex],idx:monthIndex};
      }

    setTeamOccupany(data, occupancysearch, setkcname, setTeamOccupancy) {
        let that = this;
            const col = Object.keys(data[0])
            // console.log("averageOccupancy col ", col)
            let searchString = occupancysearch
            let searchindex = col.findIndex(colname => colname.trim().toLowerCase().includes(searchString));
            // console.log("averageOccupancy index ", searchindex)
            let leadObj = {}
            data.filter((innerData) => {
                if (!leadObj[innerData[col[searchindex]]]) leadObj[innerData[col[searchindex]]] = []
                // innerData['avgOccupancy'] = 0
                // innerData['avgAvailableOccupancy'] = 0
                leadObj[innerData[col[searchindex]]].push(innerData)
            })
            // console.log("leadObj ", leadObj)
            let monIdx = ['jan','feb','mar','apr','may','jun','jul','aug','sep','oct','nov','dec']

            const specificDate = new Date();
            const specificMonthAbbreviation = this.getMonthAbbreviation(specificDate);
            console.log(specificMonthAbbreviation);

            let occCol = []
            let avaCol = []
            let searchString1 = "occupancy"
            let searchString2 = "availability"
            col.filter((colitem, idx) => {
                // console.log(colitem, idx)
                let skipmm = false
                monIdx.filter((mm,mmidx) => {
                    if(colitem.trim().toLowerCase().includes(mm) && mmidx < specificMonthAbbreviation.idx) {
                        skipmm = true;                    
                    } 
                })   
                if(!skipmm) {
                    colitem.trim().toLowerCase().includes(searchString1) && occCol.push(idx)
                    colitem.trim().toLowerCase().includes(searchString2) && avaCol.push(idx)
                }
            })
            console.log("averageOccupancy index ", col,occCol, avaCol)
    
            let leadKeyColName = [{ 'col': "Occupancy", 'colinner': 'Occupancy (%)', 'color': '#DBAE58' }, { 'col': "Availability", 'colinner': 'Availability (%)', 'color': '#488A99' }]
            let keyColName = {}
            leadKeyColName.filter((litem) => {
                let kcn = Object.keys(keyColName)
                if (!kcn.includes(litem['colinner'])) {
                    keyColName[litem['colinner']] = { 'colinner': litem['colinner'], 'color': litem['color'] }
                }
            })
            setkcname(keyColName)
            // console.log("keyColName", keyColName)
    
            let leadKeyColName1 = []
            let stackIdx = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']            

            occCol.filter((occColItem, idx) => {
                let innerLeadKey = { 'col': col[occColItem], 'stack': stackIdx[idx], 'color': '#DBAE58' }
                leadKeyColName1.push(innerLeadKey)
            })
            avaCol.filter((avaColItem, idx) => {
                let innerLeadKey = { 'col': col[avaColItem], 'stack': stackIdx[idx], 'color': '#488A99' }
                leadKeyColName1.push(innerLeadKey)
            })
            console.log("leadKeyColName1 ", leadKeyColName1)
            for(let ikey in leadObj) {
                for(let ikey1 in leadObj[ikey]) {
                    leadKeyColName1.filter((keyCol) => {
                        let keyName = keyCol['col'];
                        // console.log("h ",keyCol,leadObj[ikey])
                        if(!leadObj[ikey][ikey1][keyCol['stack']+'avg']) leadObj[ikey][ikey1][keyCol['stack']+'avg'] = 0
                        if(that.convertStringToPositiveNumber(leadObj[ikey][ikey1][keyName]) > 0) {
                            leadObj[ikey][ikey1][keyCol['stack']+'avg'] = leadObj[ikey][ikey1][keyCol['stack']+'avg'] + 1;
                        }
                    })
                }
            }
            console.log("final",leadObj)
            let leadOccuObj = {};
            let leadNames = []
            let leadNamesFull = []
            Object.keys(leadObj).filter((lead) => {
                // console.log("lead object ",lead)
                leadKeyColName1.filter((keyCol) => {
                    let keyName = keyCol['col'];
                    // console.log("keyName ",keyName, lead)
                    let avgA = 0
                    let sumA = 0
                    if (leadObj[lead].length > 0) {
                        let checkNonZeroLength = 0;
                        leadObj[lead].filter((obj, idx) => {
                            if(obj[keyCol['stack']+'avg'] > 0) checkNonZeroLength = checkNonZeroLength + 1;
                            // console.log("obj is ",keyName,idx,obj,obj[keyName],parseFloat(obj[keyName]),isNaN(parseFloat(obj[keyName])))
                            // let tao = isNaN(parseFloat(obj[keyName].trim())) ? 0 : parseFloat(obj[keyName].trim())
                            let tao = isNaN(that.convertStringToPositiveNumber(obj[keyName])) ? 0 : that.convertStringToPositiveNumber(obj[keyName])
                            console.log("tao ",tao)
                            sumA = sumA + tao
                        });
                        // console.log("sumA ",sumA)
                        // avgA = sumA / leadObj[lead].length;
                        
                        avgA = sumA > 0 ? sumA / checkNonZeroLength : 0;
                        console.log("avgA ",avgA)
                        if (!leadOccuObj[keyName]) leadOccuObj[keyName] = { 'stack': keyCol['stack'], label: keyName }
                        if (!leadOccuObj[keyName]['data']) leadOccuObj[keyName]['data'] = []
                        leadOccuObj[keyName].data.push(Math.round(avgA))
                        leadOccuObj[keyName]['color'] = keyCol['color']
                    }
                })
                if (lead === 'Global Transparency') {
                    leadNames.push('GT')
                } else if (lead === 'Ethics & Compliance') {
                    leadNames.push('E & C')
                } else if (lead === 'GBS Automation') {
                    leadNames.push('GBS')
                } else {
                    leadNames.push(lead)
                }
                leadNamesFull.push(lead)
            })
            Object.keys(leadObj).filter((lead) => {
                leadKeyColName1.filter((keyCol) => {
                    if (leadObj[lead].length > 0) {
                        leadObj[lead].filter((obj) => {
                            if(obj[keyCol['stack']+'avg']) delete obj[keyCol['stack']+'avg'];                            
                        });
                    }
                })                
            })
            // console.log("leadOccuObj ", leadOccuObj)
            // console.log("leadNames ", leadNames)
            // console.log(leadObj, leadNames, Object.values(leadOccuObj))
            setTeamOccupancy({ lead: leadNames, oldLead: leadNamesFull,series: Object.values(leadOccuObj), leadObj, ...this.returnRowCol(data,true) })
        }



    // setTeamOccupany(data, occupancysearch, setkcname, setTeamOccupancy) {
    //     const col = Object.keys(data[0])
    //     // console.log("averageOccupancy col ", col)
    //     let searchString = occupancysearch
    //     let searchindex = col.findIndex(colname => colname.trim().toLowerCase().includes(searchString));
    //     // console.log("averageOccupancy index ", searchindex)
    //     let leadObj = {}
    //     data.filter((innerData) => {
    //         if (!leadObj[innerData[col[searchindex]]]) leadObj[innerData[col[searchindex]]] = []
    //         innerData['avgOccupancy'] = 0
    //         innerData['avgAvailableOccupancy'] = 0
    //         leadObj[innerData[col[searchindex]]].push(innerData)
    //     })
    //     // console.log("leadObj ", leadObj)
    //     let occCol = []
    //     let avaCol = []
    //     let searchString1 = "occupancy"
    //     let searchString2 = "availability"
    //     col.filter((colitem, idx) => {
    //         // console.log(colitem, idx)
    //         colitem.trim().toLowerCase().includes(searchString1) && occCol.push(idx)
    //         colitem.trim().toLowerCase().includes(searchString2) && avaCol.push(idx)
    //     })
    //     // console.log("averageOccupancy index ", searchindex, occCol, avaCol)

    //     let leadKeyColName = [{ 'col': "Occupancy", 'colinner': 'Occupancy (%)', 'color': '#DBAE58' }, { 'col': "Availability", 'colinner': 'Availability (%)', 'color': '#488A99' }]
    //     let keyColName = {}
    //     leadKeyColName.filter((litem) => {
    //         let kcn = Object.keys(keyColName)
    //         if (!kcn.includes(litem['colinner'])) {
    //             keyColName[litem['colinner']] = { 'colinner': litem['colinner'], 'color': litem['color'] }
    //         }
    //     })
    //     setkcname(keyColName)
    //     // console.log("keyColName", keyColName)

    //     let leadKeyColName1 = []
    //     let stackIdx = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    //     occCol.filter((occColItem, idx) => {
    //         let innerLeadKey = { 'col': col[occColItem], 'stack': stackIdx[idx], 'color': '#DBAE58' }
    //         leadKeyColName1.push(innerLeadKey)
    //     })
    //     avaCol.filter((avaColItem, idx) => {
    //         let innerLeadKey = { 'col': col[avaColItem], 'stack': stackIdx[idx], 'color': '#488A99' }
    //         leadKeyColName1.push(innerLeadKey)
    //     })
    //     // console.log("leadKeyColName1 ", leadKeyColName1)

    //     let leadOccuObj = {};
    //     let leadNames = []
    //     Object.keys(leadObj).filter((lead) => {
    //         // console.log("lead object ",lead)
    //         leadKeyColName1.filter((keyCol) => {
    //             let keyName = keyCol['col'];
    //             // console.log("keyName ",keyName, lead)
    //             let avgA = 0
    //             let sumA = 0
    //             if (leadObj[lead].length > 0) {
    //                 leadObj[lead].filter((obj, idx) => {
    //                     // console.log("obj is ",keyName,idx,obj,obj[keyName],parseFloat(obj[keyName]),isNaN(parseFloat(obj[keyName])))
    //                     let tao = isNaN(parseFloat(obj[keyName].trim())) ? 0 : parseFloat(obj[keyName].trim())
    //                     // console.log("tao ",tao)
    //                     sumA = sumA + tao
    //                 });
    //                 // console.log("sumA ",sumA)
    //                 avgA = sumA / leadObj[lead].length;
    //                 // console.log("avgA ",avgA)
    //                 if (!leadOccuObj[keyName]) leadOccuObj[keyName] = { 'stack': keyCol['stack'], label: keyName }
    //                 if (!leadOccuObj[keyName]['data']) leadOccuObj[keyName]['data'] = []
    //                 leadOccuObj[keyName].data.push(Math.round(avgA))
    //                 leadOccuObj[keyName]['color'] = keyCol['color']
    //             }
    //         })
    //         if (lead === 'Global Transparency') {
    //             leadNames.push('GT')
    //         } else if (lead === 'Ethics & Compliance') {
    //             leadNames.push('E & C')
    //         } else if (lead === 'GBS Automation') {
    //             leadNames.push('GBS')
    //         } else {
    //             leadNames.push(lead)
    //         }
    //     })
    //     // console.log("leadOccuObj ", leadOccuObj)
    //     // console.log("leadNames ", leadNames)
    //     // console.log(leadObj, leadNames, Object.values(leadOccuObj))
    //     setTeamOccupancy({ lead: leadNames, series: Object.values(leadOccuObj), leadObj, ...this.returnRowCol(data) })
    // }


};

const Utils = new UtilsWrap()
export default Utils;