import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import './App.css';
import Footer from './components/Footer';

function App() {
  const [elementHeight, setElementHeight] = useState(0);

    useEffect(() => {
        // Function to update the height state
        const handleResize = () => {
            setElementHeight(window.innerHeight); // For example, setting the element height to 50% of the window height
        };

        // Add event listener to handle window resize
        window.addEventListener('resize', handleResize);

        // Call handleResize immediately to set the initial size
        handleResize();

        // Cleanup function to remove the event listener
        return () => window.removeEventListener('resize', handleResize);
    }, []);

  return (
      <div className="app">
        <Header />
        <div className="app-body">
          <Sidebar elementHeight={elementHeight}/>
          <main className="app-content" style={{
              minHeight: `calc(${elementHeight}px - 90px)`
            }}>
            <div className="main-container" style={{
              minHeight: `calc(${elementHeight}px - 126px)`
            }}>
              <Outlet />
            </div>
            <Footer />           
          </main>
        </div>
      </div>
  );
}

export default App;

// import { useState,useEffect } from 'react';
// import Header from './components/Header';
// import HomeBody from './components/HomeBody';
// import { Outlet } from "react-router-dom";


// import ProjectBadge from './components/ProjectBadge';
// import GridChart from './components/GridChart';
// import CostingDetails from './components/CostingDetails';
// import AccordianMenu from './components/AccordianMenu';
// import TeamMember from './components/TeamMember';
// import FlipCard from './components/FlipCard';
// import { Grid,Box } from '@mui/material';
// import ProjectStatusPie from './components/projectstatusPie';
// import Bar from './components/projectstatusBar';
// import CarouselComponent from './components/BannerCarousel';

// function App() {

//   const [projectStatusClick,setProjectStatusClick] = useState(false)
//   const [SprintDetailsClick,setSprintDetailsClick] = useState(false)
//   // const [AccordExpand,setAccordExpand]=useState(false)
//   const [projectData, setProjectData] = useState([
//     { title: 'Total Project', count: 0 },
//     { title: 'Live Project', count: 0 },
//     { title: 'Draft Project', count: 0 },
//     { title: 'Completed Project', count: 0 }
//   ]);

//   useEffect(() => {
//       // Simulated data update

//       const newData = [
//           { title: 'Total Project', count: 2 },
//           { title: 'Live Project', count: 0 },
//           { title: 'Draft Project', count: 1 },
//           { title: 'Completed Project', count: 1 }
//       ];
//       setProjectData(newData);
//   }, []);
  
//   const [costingdetails, setcostingDetailsClick] = useState(false)
//   return (
//     <div style={{ margin: '0' }}>
//             <Header />
//             <Outlet />
           
//             {/* <AccordianMenu/> */}
//         </div>
//   );
// }

// export default App;
