import { useEffect, useState } from "react";
import "../Styles/ProjectProgressMini.css"
import { ThemeProvider, createTheme, useTheme } from "@mui/material";
import Utils from "../services/Utils";
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from "@mui/x-charts/BarChart";

function MiniTeamUtilization() {
  const [assigneeList, setAssigneeList] = useState([])
  const [assigneeObjFinal, setAssigneeFinal] = useState({})
  const [kcname, setkcname] = useState({})
  const [clearLine, setClearLine] = useState(false)
  const [linedata, setLineData] = useState({});  
  async function fetchData() {
    try {
      const env = Utils.getEnvVars();
      let endpoint = env.API_URL;
      let url = endpoint + "/capacityplanning";
      const queryParams = {};
      const queryString = Object.keys(queryParams)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
        .join('&');
      if (queryString) url = `${url}?${queryString}`
      const options = {
        method: "GET"
      };
      const response = await fetch(new Request(url, options))
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const dataRes = await response.json();
      const data = 'json_data' in dataRes ? dataRes['json_data'] : []
      // const data = story_points_summary;  

      // let occCol = []
      //   let avaCol = []
      //   let searchString1 = "occupancy"
      //   let searchString2 = "availability"
      //   col.filter((colitem, idx) => {
      //       console.log(colitem, idx)
      //       colitem.trim().toLowerCase().includes(searchString1) && occCol.push(idx)
      //       colitem.trim().toLowerCase().includes(searchString2) && avaCol.push(idx)
      //   })
      //   console.log("averageOccupancy index ", searchindex, occCol, avaCol)
      // let leadKeyColName1 = []
      //   let stackIdx = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      //   occCol.filter((occColItem, idx) => {
      //       let innerLeadKey = { 'col': col[occColItem], 'stack': stackIdx[idx], 'color': '#DBAE58' }
      //       leadKeyColName1.push(innerLeadKey)
      //   })
      //   avaCol.filter((avaColItem, idx) => {
      //       let innerLeadKey = { 'col': col[avaColItem], 'stack': stackIdx[idx], 'color': '#488A99' }
      //       leadKeyColName1.push(innerLeadKey)
      //   })
      //   console.log("leadKeyColName1 ", leadKeyColName1)

     Utils.setTeamUtilizationBar (data,setAssigneeList,setAssigneeFinal,setClearLine,setkcname,false,true)
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }

  

  useEffect(() => {
    fetchData()
  }, [])  

  useEffect(() => {
    setLineData({});

    const timer = setTimeout(() => {
      setLineData(assigneeObjFinal);
    }, 1000);

    return () => clearTimeout(timer);
  }, [assigneeObjFinal]);

  const theme = useTheme();
  return (
    <div className='shadow-sm card border-light'>
      <div className='css-1dqbqs5'>
        <p class="css-nmf4xv">Team Utilization</p>
      </div>
      <div>
        <ThemeProvider theme={createTheme()}>
          <div>
            {assigneeList.length > 0 &&
              <>                
                <div>
                <div className="legendclass">
                { Object.keys(linedata).length < 1 ? <></> : 
                    <table>
                      <tr>
                        {
                          Object.values(kcname).map((itemCol) => {
                            return (
                              <td><button style={{ "position": "relative", "top": "4px", "backgroundColor": itemCol['color'], "height": "20px", "width": "20px", "border": "0px" }}></button> {itemCol['colinner']}</td>
                            )
                          })
                        }
                      </tr>
                    </table>}
                  </div> 
                  { clearLine ? <></> : 
                  // <LineChart
                  //   height={275}
                  //   series={linedata.series ? linedata.series : []}
                  //   xAxis={[{ scaleType: 'point', data: linedata.lead ? linedata.lead : [] }]}
                  //   slotProps={{
                  //     legend: {
                  //       labelStyle: {
                  //         fontSize: 10,
                  //         fill: 'blue',
                  //         display: 'none'
                  //       },
                  //       hidden: true
                  //     },
                  //     loading: true
                  //   }}
                  // /> 
                  <BarChart
                    xAxis={[{
                        scaleType: 'band', data: linedata.lead ? linedata.lead : [], categoryGapRatio: 0.3,
                        barGapRatio: 0.1
                    }]}
                    series={linedata.series ? linedata.series : []}
                    height={275}
                    barLabel="value"
                    slotProps={{
                        legend: {
                            labelStyle: {
                                fontSize: 10,
                                fill: 'blue',
                                display: 'none'
                            },
                            hidden: true
                        },
                    }}
                />
                  }                 
                </div>
              </>
            }
          </div>
        </ThemeProvider>
      </div>
    </div>
  )
}

export default MiniTeamUtilization;