import { useEffect, useState } from "react";
import "../Styles/ProjectProgressMini.css"
import { ThemeProvider, createTheme, useTheme } from "@mui/material";
import Utils from "../services/Utils";
import { LineChart } from '@mui/x-charts/LineChart';

function MiniUtilization() {
  const [csvcolname,setcsvcolname] = useState([])
  const [resdata, setResData] = useState([])
  const [assigneeList, setAssigneeList] = useState([])
  const [assigneeObj, setAssigneeObj] = useState({})
  const [assigneeObjFinal, setAssigneeFinal] = useState({})
  const [kcname, setkcname] = useState({})
  const [clearLine, setClearLine] = useState(false)
  const [linedata, setLineData] = useState({});
  useEffect(() => {
    fetchData()
  }, [])
  useEffect(() => {
    setLineData({});

    const timer = setTimeout(() => {
      setLineData(assigneeObjFinal);
    }, 1000);

    return () => clearTimeout(timer);
  }, [assigneeObjFinal]);
  async function fetchData() {
    try {
      const env = Utils.getEnvVars();
      let endpoint = env.API_URL;
      let url = endpoint + "/capacityplanning";
      const queryParams = {};
      const queryString = Object.keys(queryParams)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
        .join('&');
      if (queryString) url = `${url}?${queryString}`
      const options = {
        method: "GET"
      };
      const response = await fetch(new Request(url, options))
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const dataRes = await response.json();
      const data = 'json_data' in dataRes ? dataRes['json_data'] : []
      // const data = story_points_summary;            
      setResData(data);
      const col = Object.keys(data[0])     
      setcsvcolname(col);
      let searchString = "assignee"
      let searchindex = col.findIndex(colname => colname.trim().toLowerCase().includes(searchString));
      let assignee = {}
      data.filter((tu) => {
        if (!assignee[tu[col[searchindex]]]) assignee[tu[col[searchindex]]] = []
        assignee[tu[col[searchindex]]].push({ ...tu })
      })
      setAssigneeObj(assignee)
      setAssigneeList(Object.keys(assignee))
      Utils.setTeamUtilization (data,setAssigneeList,setAssigneeFinal,setClearLine,setkcname,true)
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }
  
  const getValue = () => {
    setClearLine(true)
    const dd = document.getElementById('dropdown');
    const value = dd.value;
    var text = dd.selectedIndex;
    if(value === "" && text === 0) {
      Utils.setTeamUtilization (resdata,setAssigneeList,setAssigneeFinal,setClearLine,setkcname,true)
      return 
    }
    Utils.setIndividualUtilization(assigneeObj, value, csvcolname, setAssigneeFinal, setClearLine, setkcname)      
  }
  
  const theme = useTheme();
  return (
    <div className='shadow-sm card border-light'>
      <div className='css-1dqbqs5'>
        <p class="css-nmf4xv">Individual Utilization</p>
      </div>
      <div>
        <ThemeProvider theme={createTheme()}>
          <div>
            {assigneeList.length > 0 &&
              <>
                <div className="align-text-center"><label for="dropdown" className="dd-label">Assignee: </label>
                  <select name="dropdown" className="utilization-select-class" id="dropdown" onChange={getValue}>
                    <option value="" className="def-dd-option  dd-list">Select your option</option>
                    {
                      assigneeList.map((key,idx) => (                        
                        <option className='dd-list' value={key}>{key}</option>
                      ))
                    }
                  </select></div>
                <div>
                <div className="legendclass">
                { Object.keys(linedata).length < 1 ? <></> : 
                    <table>
                      <tr>
                        {
                          Object.values(kcname).map((itemCol) => {
                            return (
                              <td><button style={{ "position": "relative", "top": "4px", "backgroundColor": itemCol['color'], "height": "20px", "width": "20px", "border": "0px" }}></button> {itemCol['colinner']}</td>
                            )
                          })
                        }
                      </tr>
                    </table>}
                  </div> 
                  { clearLine ? <></> : <LineChart
                    height={275}
                    series={linedata.series ? linedata.series : []}
                    xAxis={[{ scaleType: 'point', data: linedata.lead ? linedata.lead : [] }]}
                    slotProps={{
                      legend: {
                        labelStyle: {
                          fontSize: 10,
                          fill: 'blue',
                          display: 'none'
                        },
                        hidden: true
                      },
                      loading: true
                    }}
                  /> }                 
                </div>
              </>
            }
          </div>
        </ThemeProvider>
      </div>
    </div>
  )
}

export default MiniUtilization;