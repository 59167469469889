import React from 'react';
import ProjectProgressMini from './ProjectProgressMini';
import AvergaOccupancy from './AvergaOccupancy';
// import ActiveHC from './ActiveHC';
import ProjectDataDashboard from './ProjectDataDashboard';
import MiniUtilization from './MiniUtilization';
import MiniTeamUtilization from './MiniTeamUtilization';

function Home() {
  return (
    <div>
      <h2>Dashboard</h2>
      {/* css-akw81x  */}
      <div className='row'>
        <div className='justify-content-md-center row'>
          <ProjectDataDashboard />
        </div>
        <div className='row'>
          <div className='mb-4 col-xl-6 col-12'>
            <ProjectProgressMini />
          </div>
          <div className='mb-4 col-xl-6 col-12'>
            <div className='row'>
              <AvergaOccupancy />
            </div>
            <br />
            <div className='row'>
              <MiniTeamUtilization />
            </div>
            <br />
            <div className='row'>
              <MiniUtilization />
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
}

export default Home;