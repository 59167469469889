import React from 'react';
import '../Styles/Team.css';

import MadhusudanKulkarni from '../img/MadhusudanKulkarni.png';
import kunalkishor from '../img/kunalkishor.jpg';
import LAKSHMINARASIMHANSR from '../img/LAKSHMINARASIMHANSR.jpg';
import GayathriReddy from "../img/GayathriReddy.jpg";
import raghavendraachar from "../img/raghavendraachar.jpg";
import NithenVarghese from "../img/NithenVarghese.jpg";
import RahulThakur from "../img/RahulThakur.jpg"
import AnuragKumarSinha from "../img/AnuragKumarSinha.jpg"
import SantanuBanerjee from "../img/SantanuBanerjee.jpg"
import SmritiRekhaDeka from "../img/SmritiRekhaDeka.jpg"
import AyitriSadhu from "../img/AyitriSadhu.jpg"
import AndreaPeccatiello from "../img/AndreaPeccatiello.jpg"
import AshishKumarJaiswal from "../img/AshishKumarJaiswal.jpg"
import ChynaDeLosAngeles from "../img/ChynaDeLosAngeles.jpg"
import CiaranJones from "../img/CiaranJones.jpg"
import RishabhSachdeva from "../img/RishabhSachdeva.jpg"
import SubhajitNayak from "../img/SubhajitNayak.jpg"
import SubratKumarKar from "../img/SubratKumarKar.jpg"

import RaviKumar from "../img/cache.jpg"
import TrinathKatta from "../img/cache.jpg"
import KiranRJ from "../img/cache.jpg"
import AllaPrathapaReddy from "../img/cache.jpg"
import RAMKISHANDHANANJAYJAGTAP from "../img/cache.jpg"
import SRAVYASREEKANAGALA from "../img/cache.jpg"

const teamMembers = [
    {
        name: 'Madhusudan Kulkarni',
        image: MadhusudanKulkarni
    },
    {
        name: 'Kunal Kishor',
        image: kunalkishor
    },
    {
        name: 'LAKSHMI NARASIMHAN S R',
        image: LAKSHMINARASIMHANSR
    },
    {
        name: 'Gayathri Reddy',
        image: GayathriReddy
    },
    {
        name: 'Nithen Varghese',
        image: NithenVarghese
    },
    {
        name: 'Raghavendra Achar',
        image: raghavendraachar
    },
    {
        name: 'Rahul Thakur',
        image: RahulThakur
    },
    {
        name: 'Anurag Kumar Sinha',
        image: AnuragKumarSinha
    },
    {
        name: 'Santanu Banerjee',
        image: SantanuBanerjee
    },
    {
        name: 'Smriti Rekha Deka',
        image: SmritiRekhaDeka
    },
    {
        name: 'Ayitri Sadhu',
        image: AyitriSadhu
    },
    {
        name: 'Subhajit Nayak',
        image: SubhajitNayak
    },
    {
        name: 'Ashish Kumar Jaiswal',
        image: AshishKumarJaiswal
    },
    {
        name: 'Subrat Kumar Kar',
        image: SubratKumarKar
    },
    {
        name: 'Andrea Peccatiello',
        image: AndreaPeccatiello
    },
    {
        name: 'Chyna De Los Angeles',
        image: ChynaDeLosAngeles
    },
    {
        name: 'Ciaran Jones',
        image: CiaranJones
    },
    {
        name: 'Rishabh Sachdeva',
        image: RishabhSachdeva
    },
    {
        name: 'Ravi Kumar',
        image: RaviKumar
    },
    {
        name: 'Trinath Katta',
        image: TrinathKatta
    },
    {
        name: 'Kiran RJ',
        image: KiranRJ
    },
    {
        name: 'Alla Prathapa Reddy',
        image: AllaPrathapaReddy
    },
    {
        name: 'RAMKISHAN DHANANJAY JAGTAP',
        image: RAMKISHANDHANANJAYJAGTAP
    },
    {
        name: 'SRAVYA SREE KANAGALA',
        image: SRAVYASREEKANAGALA
    }
];

function Team() {
    return (
        <div className="team-container">
            {teamMembers.map((member, index) => (
                <div className="team-member" key={index}>
                    <img src={member.image} alt={`${member.name}`} className="team-image" />
                    <h2>{member.name}</h2>
                </div>
            ))}
        </div>
    );
}

export default Team;