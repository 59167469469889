import { Button } from "@mui/material";
import { useEffect, useState } from "react";
// import costing_details from "../data/costing_details.json";
import Utils from "../services/Utils";

function Costing() {
    const [costingDetails, setCostingDetials] = useState([])
    const [text, settext] = useState('')
    async function fetchData() {
        try {
            const env = Utils.getEnvVars();
            let endpoint = env.API_URL;
            let url = endpoint + "/CostingDetails";        
            console.log(url)   
            const queryParams = {
            };
            const queryString = Object.keys(queryParams)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
                .join('&');
            if (queryString) url = `${url}?${queryString}`      
            const options = {
              method: "GET"
            };
            const response = await fetch(new Request(url, options))
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const dataRes = await response.json();
            const data = 'json_data' in dataRes ? dataRes['json_data'] : []
            setCostingDetials(data)
            console.log('Data received:', data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const keys = Object.keys(costingDetails).length > 0 ? Object.keys(costingDetails[0]) : [];
    const rows = costingDetails
    console.log(rows[0])
    const getValue = () => {
        const dd = document.getElementById('dropdown');
        const value = dd.value;
        var text = dd.selectedIndex;

        console.log(value);
        console.log(text)
        console.log(rows[text].url);
        settext(rows[text].url)
    }
    return (
        <div>
            <h2>Costing</h2>
            <div className='costing-dd' sx={{ backgroundColor: 'grey', padding: '5px' }}>

                <label for="dropdown" className="dd-label">Choose an Account: </label>

                <select name="dropdown" id="dropdown" onChange={getValue}>
                    <option value="" className="def-dd-option  dd-list">Select your option</option>

                    {
                        Object.keys(rows).map((key) => (

                            <option className='dd-list' value={rows[key].id}>{rows[key].name}</option>
                        ))
                    }
                </select>
                <Button sx={{ backgroundColor: '#b92b27', color: 'white', ':hover': { backgroundColor: '#bf1617' } }}
                    onClick={() => window.open(text, '_blank') } style={{ 'margin-left': '20px' }} > Get Costing
                </Button>




            </div>
        </div>
    )
}

export default Costing;