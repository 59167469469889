import UnderConstruction from "./UnderConstruction";

function UpcomingSprints () {
    return (
        <div>
            <h2>Upcoming Sprints</h2>
            <UnderConstruction />
        </div>
    )
}

export default UpcomingSprints;